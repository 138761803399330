import React, { useCallback, useEffect, useState } from 'react';
import { Resizable, ResizeCallbackData } from 'react-resizable';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import { Endpoint } from '@jsplumb/community/types/core';
import HolderOutlined from '@ant-design/icons/HolderOutlined';
import CaretRightFilled from '@ant-design/icons/CaretRightFilled';
import CaretDownFilled from '@ant-design/icons/CaretDownFilled';
import { eventHistory } from '@kemu-io/kemu-core/common';
import { EventHistoryItem } from '@kemu-io/kemu-core/types';
import { DataType, SupportedTypes } from '@kemu-io/hs-types';
import classNames from 'classnames';
import { Inspector } from 'react-inspector';
import { useSelector, useDispatch } from 'react-redux';
import { useResizeDetector } from 'react-resize-detector';
import styles from './WidgetPortInspector.module.css';
import { customTheme } from './inspectorTheme.ts';
import { LM_CANVAS_CONTAINER_CLASS, GATE_PORT_CLASS, TRIGGER_PORT_CLASS, TARGET_PORT_CLASS } from '@common/constants';
import { dataTypeToHumanReadable, decodeDomId } from '@common/utils';
import { KemuEndpointData } from '@src/types/core_t';
import { useTranslation } from '@hooks/index';
import { currentRecipePoolId, selectOpeningRecipe } from '@src/features/Workspace/workspaceSlice.ts';
import { selectLogicMapperSettings, setLogicMapperSettingValue } from '@src/features/interface/interfaceSlice.ts';

const minContractedPanelSize = { width: 230, height: 125 };
const minExpandedPanelSize = { width: 166, height: 200 };
const ignoreObjectTypes = [DataType.AudioBuffer, DataType.ArrayBuffer, DataType.ImageBitmap];

type ElementWithJTKEndpoint = HTMLElement & {
  jtk?: {
    endpoint: Endpoint;
  }
}

type PortInfo = {
  isOutput: boolean;
  portType: DataType | DataType[];
  lastTimestamp?: number;
  lastDetectedEventType?: DataType;
  lastDetectedValue?: string | number | boolean | Record<string, unknown>;
  lastValueIsObject?: boolean;
  isTriggerPort?: boolean;
}

type Props = {
  containerEl: HTMLDivElement | null;
}

const PORT_INSPECTOR_WIDTH = minContractedPanelSize.width;
const PORT_INSPECTOR_HEIGHT = minContractedPanelSize.height;
const PORT_INSPECTOR_MARGIN = 10;

/**
 * Loops through all the properties of an object and removes any that are not serializable
 * such as ArrayBuffer, ImageBitmap, etc.
 * @param obj 
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const removeArrayBufferProps = (obj: any): SupportedTypes => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => removeArrayBufferProps(item)) as SupportedTypes;
  }

  if (obj instanceof ImageData) {
    return {
      width: obj.width,
      height: obj.height,
      colorSpace: obj.colorSpace,
      data: '<Uint8ClampedArray>',
    };
  }

  const finalObj: Record<string, unknown> = {};
  const objKeys = Object.keys(obj);

  objKeys.forEach((key) => {
    const value = obj[key];
    if (value instanceof ArrayBuffer) {
      finalObj[key] = '<ArrayBuffer>';
    } else if (value instanceof ImageData) {
      finalObj[key] = '<ImageData>';
    } else if (value instanceof AudioBuffer) {
      finalObj[key] = '<AudioBuffer>';
    } else if (value instanceof Uint8ClampedArray) {
      finalObj[key] = '<Uint8ClampedArray>';
    } else if (Array.isArray(value)) {
      finalObj[key] = value.map((item) => removeArrayBufferProps(item));
    } else if (typeof value === 'object') {
      finalObj[key] = removeArrayBufferProps(value as Record<string, unknown>);
    } else {
      finalObj[key] = value;
    }
  });

  return finalObj as SupportedTypes;
};

const WidgetPortInspector = (props: Props) => {
  const { containerEl } = props;
  const [panelSize, setPanelSize] = useState<{ width: number, height: number }>({ ...minContractedPanelSize });
  const [portInfo, setPortInfo] = useState<PortInfo | null>(null);
  const [lastExpandedSize, setLastExpandedSize] = useState<{ width: number, height: number }>({ ...minExpandedPanelSize });
  const [expandObjectDetails, setExpandObjectDetails] = useState(false);
  const userSettings = useSelector(selectLogicMapperSettings);
  const defaultPanelPosition = userSettings.portInspectorPosition;
  const [panelPosition, setPanelPosition] = useState(defaultPanelPosition);
  const { ref, width, height } = useResizeDetector();
  const recipePoolId = useSelector(currentRecipePoolId);
  const openingRecipe = useSelector(selectOpeningRecipe);
  const dispatch = useDispatch();
  const t = useTranslation();
  const wpT = t.withBaseKey('LogicMapper.WidgetPortDebugPanel');
  const portTypes = Array.isArray(portInfo?.portType) ? portInfo?.portType : [portInfo?.portType];
  const portTypeNames = portTypes?.map((type) => dataTypeToHumanReadable(t, type as DataType));
  const showDetailsPanel = portInfo?.lastValueIsObject && expandObjectDetails;

  const handleResize = (event: React.SyntheticEvent, data: ResizeCallbackData) => {
    if (expandObjectDetails) {
      setLastExpandedSize({
        width: Math.max(minContractedPanelSize.width, data.size.width),
        height: Math.max(minContractedPanelSize.height, data.size.height),
      });
    }
    setPanelSize({
      width: Math.max(minContractedPanelSize.width, data.size.width),
      height: Math.max(minContractedPanelSize.height, data.size.height),
    });
  };

  const togglePanelExpansion = () => {
    const nextExpanded = !expandObjectDetails;
    if (nextExpanded) {
      // Make sure the expanded dimensions are large enough
      const nextW = panelSize.height < minExpandedPanelSize.height ? minExpandedPanelSize.height : panelSize.height;
      const nextH = panelSize.width < minExpandedPanelSize.width ? minExpandedPanelSize.width : panelSize.width;
      setPanelSize({ width: Math.max(lastExpandedSize.width, nextW), height: Math.max(lastExpandedSize.height, nextH) });
    } else {
      setPanelSize({ width: minContractedPanelSize.width, height: minContractedPanelSize.height });
    }

    setExpandObjectDetails(!expandObjectDetails);
  };

  const handleStopDragging = (_: DraggableEvent, data: DraggableData) => {
    setPanelPosition({
      x: data.x,
      y: data.y,
    });

    dispatch(setLogicMapperSettingValue({
      key: 'portInspectorPosition',
      value: {
        x: data.x,
        y: data.y,
      },
    }));
  };

  const processPortClick = useCallback((e: HTMLElement) => {
    const target = e as ElementWithJTKEndpoint;
    const isTriggerPort = target.classList.contains(TRIGGER_PORT_CLASS);
    const isTargetPort = target.classList.contains(TARGET_PORT_CLASS);
    const endpointPort = target.jtk?.endpoint;
    const endpointElement = endpointPort?.element as HTMLElement;
    const widgetInfo = decodeDomId(endpointElement.id);
    const portData = endpointPort?.getData() as KemuEndpointData;
    let history: EventHistoryItem | null = null;

    if (widgetInfo?.gateId) {
      if (isTargetPort) {
        history = eventHistory.getLastReceivedPortEvent(widgetInfo?.blockId, widgetInfo?.gateId, portData.name);
      } else {
        history = eventHistory.getLastProducedPortEvent(widgetInfo?.blockId, widgetInfo?.gateId, portData.name);
      }
    }

    let lastValue = history?.data.value;
    const lastValueIsObject = typeof history?.data.value === 'object'
            && !ignoreObjectTypes.includes(history?.data.type as DataType);

    if (lastValueIsObject) {
      lastValue = removeArrayBufferProps(lastValue);
    }

    setPortInfo({
      isOutput: !isTargetPort,
      portType: portData.type,
      lastDetectedEventType: history?.data.type as DataType,
      lastDetectedValue: lastValue as string | number | boolean | Record<string, unknown>,
      lastTimestamp: history?.data.timestamp as number,
      lastValueIsObject,
      isTriggerPort,
    });
  }, []);

  useEffect(() => {
    if (!recipePoolId) { return; }
    const canvasContainer = document.querySelector(`.${LM_CANVAS_CONTAINER_CLASS}`);

    const handleClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (target.classList.contains(GATE_PORT_CLASS)) {
        processPortClick(target);
      }
    };

    canvasContainer?.addEventListener('click', handleClick as EventListener);

    return () => {
      canvasContainer?.removeEventListener('click', handleClick as EventListener);
    };
  }, [processPortClick, recipePoolId]);

  useEffect(() => {
    if (containerEl) {
      ref.current = containerEl;
    }
  }, [containerEl, ref]);

  // Prevents panel from being outside the visible workspace
  useEffect(() => {
    if (width && height) {
      setPanelPosition((current) => {
        const panelX = width && width < (current.x + PORT_INSPECTOR_WIDTH + PORT_INSPECTOR_MARGIN) ? width - PORT_INSPECTOR_WIDTH - PORT_INSPECTOR_MARGIN : current.x;
        const panelY = height && height < (current.y + PORT_INSPECTOR_HEIGHT + PORT_INSPECTOR_MARGIN) ? height - PORT_INSPECTOR_HEIGHT - PORT_INSPECTOR_MARGIN : current.y;
        return {
          x: panelX,
          y: panelY,
        };
      });
    }
  }, [width, height]);

  if (openingRecipe || !recipePoolId)  { return null; }

  const lastValueIsPrintable = portInfo?.lastDetectedEventType === DataType.String
    || portInfo?.lastDetectedEventType === DataType.Number
    || portInfo?.lastDetectedEventType === DataType.Boolean;

  const textModeValue = portInfo?.lastDetectedValue?.toString();
  const longTextMode = lastValueIsPrintable && (textModeValue?.length || 0) > 12;

  return (
    <Draggable
      onStop={handleStopDragging}
      handle={`.${styles.DragIcon}`}
      bounds="parent"
      position={panelPosition}
    >
      <Resizable
        height={panelSize.height}
        width={panelSize.width}
        minConstraints={showDetailsPanel
          ? [minExpandedPanelSize.width, minExpandedPanelSize.height]
          : [minContractedPanelSize.width, minContractedPanelSize.height]
        }
        resizeHandles={['se']}
        onResize={handleResize}
      >
        <div
          className={styles.Inspector}
          style={{
            width: panelSize.width,
            height: panelSize.height,
          }}
        >
          <div className={styles.DragHandle}>
            <span className={styles.DragIcon}>
              <HolderOutlined />
            </span>
            <span className={styles.DragText}>
              {wpT('Title')}
            </span>
          </div>

          <div className={styles.InspectorBody}>
            {!portInfo && (
              <div className={styles.NoData}>
                {wpT('ClickToInspect')}
              </div>
            )}


            {portInfo && (
              <>
                <div className={styles.LineItem}>
                  <div className={styles.LineItemLabel}>{wpT('DataType')}:</div>
                  <div className={styles.LineItemValue}>
                  {portTypeNames?.length ? portTypeNames?.join(', ') : wpT('NotAvailable', 'N/A')}
                  </div>
                </div>

                <div className={styles.LineItem}>
                  <div className={styles.LineItemLabel}>{wpT('PortType')}:</div>
                  <div className={styles.LineItemValue}>
                  {portInfo?.isOutput ? wpT('SourcePort') : wpT('TargetPort')}
                  </div>
                </div>

                <div className={classNames(styles.LineItem)}>
                  <div className={styles.LineItemLabel}>{wpT('LastValue', 'Last Value')}:</div>
                  <div className={
                    classNames(styles.LineItemValue, styles.Result, {
                      [styles.Clickable]: portInfo?.lastValueIsObject,
                    })}
                    onClick={portInfo?.lastValueIsObject ? togglePanelExpansion : undefined}
                  >
                    {portInfo?.lastValueIsObject ? (
                      <>
                        <span>
                          {Array.isArray(portInfo.lastDetectedValue)
                            ? dataTypeToHumanReadable(t, DataType.Array)
                            : ( portInfo?.lastDetectedEventType === DataType.ImageData
                              ? wpT('Image', 'Image')
                              : dataTypeToHumanReadable(t, DataType.JsonObj)
                            )
                          }
                        </span>
                        <span>
                          {expandObjectDetails ? (
                            <CaretDownFilled  />
                          ) : (
                            <CaretRightFilled />
                          )}
                        </span>
                      </>
                    ): (
                      (portInfo?.lastDetectedValue !== undefined && portInfo?.lastDetectedValue !== null) ? (
                        <>
                          {portInfo?.lastDetectedEventType === DataType.ImageData && (
                            <span>{wpT('Image', 'Image')}</span>
                          )}

                          {portInfo?.lastDetectedEventType === DataType.AudioBuffer && (
                            <span>{wpT('Audio', 'Audio')}</span>
                          )}

                          {lastValueIsPrintable && (
                            longTextMode ? (
                              <span>
                                <CaretDownFilled />
                              </span>
                            ) : (
                              <span>{textModeValue}</span>
                            )
                          )}
                        </>
                      ) : ('N/A')
                    )}
                  </div>
                </div>

                {lastValueIsPrintable && longTextMode && (
                  <div className={styles.TextModeValue}>
                    <span>{textModeValue}</span>
                  </div>
                )}

                <div className={classNames(styles.DetailsPanel,
                  {
                    [styles.Visible]: expandObjectDetails,
                  }
                )}>
                  {
                    (
                      portInfo?.lastDetectedEventType === DataType.JsonObj
                      || portInfo?.lastDetectedEventType === DataType.Array
                      || portInfo?.lastDetectedEventType === DataType.Rect
                      || portInfo?.lastDetectedEventType === DataType.Point
                      || portInfo?.lastDetectedEventType === DataType.BinaryFile
                      || portInfo?.lastDetectedEventType === DataType.ImageData
                    ) && (
                      <Inspector
                        data={portInfo?.lastDetectedValue}
                        table={false}
                        // First level always expanded
                        expandLevel={1}
                        // @ts-expect-error bad typings
                        theme={customTheme}
                      />
                    )
                  }
                </div>
              </>
            )}
          </div>
        </div>
      </Resizable>
    </Draggable>
  );
};

export default WidgetPortInspector;
