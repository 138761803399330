import 'react-perfect-scrollbar/dist/css/styles.css';
import React, { ReactElement, useCallback, useState, useRef } from 'react';
import { Menu, Layout, theme, Button, Tooltip } from 'antd';
// import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch, useSelector } from 'react-redux';
import Icon, {
	FileOutlined,
	HomeOutlined,
	DownloadOutlined,
	ShoppingCartOutlined,
	ImportOutlined,
} from '@ant-design/icons';
// import { ReactSVG } from 'react-svg';
import { useNavigate } from 'react-router-dom';
import { CustomWidgetVariant, RecipeType } from '@kemu-io/kemu-types';
import classNames from 'classnames';
import LogoAndText from '../../assets/img/kemu-logo-text.png';
import Logo from '../../assets/img/kemu-logo.png';
import { ReactComponent as RecipeIcon } from '../../assets/img/menus/recipe.svg';
import { ReactComponent as NewRecipeTabIcon } from '../../assets/img/menus/new-recipe-tab.svg';
import { ReactComponent as SaveWidgetIcon } from '../../assets/img/menus/save-widget-icon.svg';
import { ReactComponent as SaveIcon } from '../../assets/img/menus/save.svg';
import { ReactComponent as SaveAsIcon } from '../../assets/img/menus/save-as.svg';
import { currentRecipePoolId, selectCurrentRecipe, selectedBlock, selectVisibleGroup } from '../../features/Workspace/workspaceSlice';
import StatusIndicator from '../statusIndicator/statusIndicator';
import { getStoredConfig, setStoredConfig } from '../../common/utils';
import globals from '../../common/globals';
import routes from '../../common/routes/index';
import ExportRecipeDialog from '../ExportRecipeDialog/ExportRecipeDialog';
import styles from './mainMenu.module.css';
import AnimatedUpdateIcon from './AnimatedUpdateIcon/AnimatedUpdateIcon';
import useFileHandle from '@hooks/useFileHandle.ts';
import useTranslation from '@hooks/useTranslation';
import { selectCurrentWidget } from '@src/app/reducers/widget/widgetSlice';
import { saveWidgetAction } from '@src/app/reducers/widget/saveWidgetReducer';
import { updateWidgetAction } from '@src/app/reducers/widget/updateWidgetReducer';
import { storeRecipeAction } from '@src/features/interface/reducers/storeRecipeReducer';
import AccountMenu from '@components/AccountMenu/AccountMenu';
import useHasPosthogFeature from '@hooks/useHasPosthogFeature';
import SaveRecipeTypeModal from '@components/SaveRecipeTypeModal/SaveRecipeTypeModal';
import { saveRecipeToFileAction } from '@src/features/interface/reducers/saveRecipeToFileReducer';
import { importFileRecipeAction } from '@src/app/reducers/workspace/importFileRecipeReducer';
import useHubUpdateDetector from '@common/hooks/useHubUpdateDetector';
import useAlert from '@components/alert/useAlert';

const { Sider } = Layout;
interface Props {
	collapsed: boolean
}

interface MenuConfig {
	openedMenus: string[];
}

enum MarketplaceTarget {
	PublicRecipes,
	MyRecipes,
	HubServices,
}

const CONFIG_NAME = 'selectedMenus';
const defaultConfig: MenuConfig = {
	openedMenus: ['apps']
};

export const getRecipeContents = async (recipeId: string): Promise<{
  recipe: any;
  storage?: ArrayBuffer;
}> => {
  // TODO: Implement actual recipe content retrieval
  return {
    recipe: {
      id: recipeId,
      name: 'Dummy Recipe',
      blocks: {},
      connections: [],
      version: '1.0.0',
    },
    storage: new ArrayBuffer(0),
  };
};

function MainMenu ({ collapsed }: Props): ReactElement {
	const dispatch = useDispatch();
	const Alert = useAlert();
	// const hbList = useSelector(hardwareBlocks);
	const [openedItems, setOpenedItems] = useState<MenuConfig>(getStoredConfig(CONFIG_NAME, defaultConfig));
	const { token: { colorBgContainer } } = theme.useToken();
	const navigate = useNavigate();
	const t = useTranslation('Menu');
	const [hasMarketplaceAccess] = useHasPosthogFeature('marketplace-access');
	const visibleGroup = useSelector(selectVisibleGroup);
	const currentRecipe = useSelector(selectCurrentRecipe);
	const currentBlock = useSelector(selectedBlock);
	// const showRedDot = useSelector(unsavedChanges);
	const recipePoolId = useSelector(currentRecipePoolId);
	const currentWidget = useSelector(selectCurrentWidget);
	const [showExportDialog, setShowExportDialog] = useState(false);
	const [showSaveModal, setShowSaveModal] = useState(false);
	const saveAsRef = useRef(false);
	const { fileHandle, setFileHandle } = useFileHandle();
	const { updateInfo, rebootToInstall } = useHubUpdateDetector();
	const openMarketplace = useCallback((target: MarketplaceTarget) => {
		if (target === MarketplaceTarget.PublicRecipes) { navigate(routes.marketplace.getPublicationListRoute('recipe')); };
		if (target === MarketplaceTarget.MyRecipes) { navigate(routes.marketplace.getMyRecipesRoute()); };
		if (target === MarketplaceTarget.HubServices) { navigate(routes.marketplace.getHubServicesRoute()); };
	}, [navigate]);

	const createNewRecipe = useCallback(() => {
		window.open(`https://${globals.KEMU_APP_DOMAIN}`, '_blank');
	}, []);

	const createNewRecipeHere = useCallback(() => {
		window.open(`https://${globals.KEMU_APP_DOMAIN}`, '_self');
	}, []);

	const handleExportRecipe = useCallback(() => {
		setShowExportDialog(true);
	}, []);

	const handleExportDialogClose = useCallback(() => {
		setShowExportDialog(false);
	}, []);

	// Detect if the widget already exists, and if so, call update instead
	const saveWidgetHandler = useCallback(() => {
		if (currentBlock?.recipeId && visibleGroup?.groupId && recipePoolId) {

			if (!currentWidget) {
				dispatch(saveWidgetAction({
					recipeId: recipePoolId,
					thingId: currentBlock?.recipeId,
					widgetIdInRecipe: visibleGroup?.groupId,
					variant: CustomWidgetVariant.Group,
				}));
			} else {
				dispatch(updateWidgetAction({
					widgetDbId: currentWidget,
					recipeId: recipePoolId,
					thingId: currentBlock.recipeId,
					widgetRecipeId: visibleGroup.groupId,
					variant: CustomWidgetVariant.Group,
				}));
			}
		}
	}, [dispatch, visibleGroup, currentWidget, currentBlock, recipePoolId]);

	const saveToCloud = useCallback(() => {
		// FIXME: restore showRedDot
    // if (/*showRedDot && */ recipePoolId) {
		if (recipePoolId && currentRecipe.type !== RecipeType.Desktop) {
			dispatch(storeRecipeAction({
				recipePoolId: recipePoolId,
				navigate,
				saveAsNew: saveAsRef.current,
			}));

			saveAsRef.current = false;
		}
	}, [dispatch, recipePoolId, navigate, currentRecipe.type]);

	const handleConfirmBeforeUpdate = useCallback(() => {
		Alert.confirm({
			title: t('Update.Confirm'),
			iconColor: 'info',
			content: t('Update.Confirm.Content'),
			okText: t.withBaseKey('CommonWords')('Update'),
			// okButtonColor: 'danger',
			cancelText: t.withBaseKey('CommonWords')('Cancel'),
			onOk: rebootToInstall
		});
	}, [t, rebootToInstall, Alert]);

	const saveToFile = useCallback(async () => {
		if (!recipePoolId) {
			return;
		}

		await dispatch(saveRecipeToFileAction({
			navigate,
			handle: fileHandle,
			saveHandle: setFileHandle,
		}));
	}, [recipePoolId, navigate, dispatch, fileHandle, setFileHandle]);

	const handleSaveClick = useCallback(() => {
		if (currentRecipe.type === RecipeType.Desktop) {
			saveToFile();
		} else if (currentRecipe.type === RecipeType.Browser && currentRecipe.entityInfo?.dbId) {
			saveToCloud();
		} else {
			setShowSaveModal(true);
		}
	}, [currentRecipe.type, currentRecipe.entityInfo?.dbId, saveToFile, saveToCloud]);

	const handleSaveAs = useCallback(() => {
		// Reset handler to force users to choose a target file
		setFileHandle(null);
		saveAsRef.current = true;
		setShowSaveModal(true);
	}, [setFileHandle]);

	const handleSaveModalClose = useCallback(() => {
		setShowSaveModal(false);
		saveAsRef.current = false;
	}, []);

	const onOpenChange = (keys: React.ReactText[]) => {
		// when the menu collapses, keys is an empty array and it would delete everything 
		if (!collapsed) {
			setOpenedItems({
				openedMenus: keys as string[]
			});

			setStoredConfig<MenuConfig>(CONFIG_NAME, {
				openedMenus: keys as string[]
			} as MenuConfig, true);
		}
	};

	const handleImportRecipe = useCallback(() => {

		dispatch(importFileRecipeAction({
			navigate,
			// IMPORTANT: we don't pass the current handle to force the user to pick a new file
			saveHandle: setFileHandle,
		}));
	}, [dispatch, navigate, setFileHandle]);

	const inDevServer = window.location.host.includes('dev.kemu.io');

	return (
		<Sider
			style={{ background: colorBgContainer }}
			width={250}
			trigger={null}
			collapsible
			collapsed={collapsed}
		>
			<div className={styles.SidebarContainer}>
				<div>
					{/* Logo */}
					<div className={styles.SidebarBrand}>
						<a href="/">
							{!collapsed ? (
								<img alt="image" src={LogoAndText} className={styles.HeaderLogo} />
							) : (
								<img alt="image" src={Logo} className={styles.CollapsedHeaderLogo} />
							)}
						</a>
					</div>

					{/* Menu */}
					<Menu
						onOpenChange={onOpenChange}
						triggerSubMenuAction="hover"
						defaultOpenKeys={!collapsed ? openedItems.openedMenus: []}
						mode="inline"
						selectable={false}
						subMenuCloseDelay={0.25}
						className={classNames(styles.Menu, collapsed && styles.Collapsed)}
						items={[
							{
								key: 'home',
								icon: <HomeOutlined style={{ fontSize: 26 }} />,
								title: collapsed ? t('Home') : '',
								label: t('Home'),
								className: styles.LargeMenu,

								children: [
									{
										key: 'new-recipe-here',
										icon: <FileOutlined />,
										label: t('NewRecipeHere'),
										onClick: createNewRecipeHere,
									},
									{
										key: 'new-recipe',
										icon: <Icon component={NewRecipeTabIcon} />,
										label: t('NewRecipeTab'),
										onClick: createNewRecipe,
									},
									{
										key: 'my-recipes-item',
										icon: <Icon component={RecipeIcon} />,
										label: t('MyRecipes'),
										onClick: () => openMarketplace(MarketplaceTarget.MyRecipes),
									},
									{
										key: 'import-recipe',
										icon: <ImportOutlined />,
										label: t('ImportRecipe'),
										onClick: handleImportRecipe,
									},
									{
										key: 'export-recipe',
										icon: <DownloadOutlined />,
										label: t('ExportRecipe'),
										onClick: handleExportRecipe,
									},
									// {
									// 	key: 'marketplace-item',
									// 	icon: <ShoppingCartOutlined />,
									// 	label: t('Marketplace'),
									// 	onClick: () => openMarketplace(MarketplaceTarget.PublicRecipes),
									// },
								]
							},
							{
								key: 'save',
								className: styles.LargeMenu,
								icon: <Icon component={SaveIcon} style={{ fontSize: 26 }} />,
								title: collapsed ? t('SaveRecipeTooltip') : '',
								label: t('SaveRecipeTooltip'),
								children: [
									{
										key: 'save-recipe',
										icon: <Icon component={SaveIcon} />,
										label: t('SaveRecipe'),
										onClick: handleSaveClick,
									},
									{
										key: 'save-as',
										icon: <Icon component={SaveAsIcon} style={{ fontSize: 16 }} />,
										label: t('SaveRecipeAs'),
										onClick: handleSaveAs,
									},
								],
							},
							...(visibleGroup ? [
								{
									key: 'save-widget',
									className: styles.LargeMenu,
									icon: <Icon component={SaveWidgetIcon} style={{ fontSize: 26 }} />,
									title: collapsed ? t('SaveWidgetTooltip') : '',
									label: t('SaveWidgetTooltip'),
									onClick: saveWidgetHandler,
								},
							]: []),
							...(hasMarketplaceAccess ? [
								{
									key: 'marketplace',
									className: styles.LargeMenu,
									label: t('Marketplace'),
									icon: <ShoppingCartOutlined style={{ fontSize: 26 }} />,
										// icon: (
									// 	<Icon
									// 		component={visibleGroup ? SaveWidgetIcon : SaveRecipeIcon}
									// 		style={{ fontSize: 26 }}
									// 	/>
									// ),
									onClick: () => openMarketplace(MarketplaceTarget.HubServices),
								},
							] : []),
						]}
					/>
				</div>

				<div className={styles.PushDownItems}>
					{updateInfo && (
						<div className={styles.HubUpdateButton}>
							<Tooltip title={t('Update', undefined, { version: updateInfo.version })} placement='right'>
								<Button type="text" icon={<AnimatedUpdateIcon />} onClick={handleConfirmBeforeUpdate} />
							</Tooltip>
						</div>
					)}

					{/* User Menu */}
					<AccountMenu className={styles.ProfileMenu} />
					<StatusIndicator />
					<span
						className={classNames(styles.VersionBox, inDevServer && styles.Staging)}
					>
						v{globals.WEB_APP_VERSION}
					</span>
				</div>

				<ExportRecipeDialog
					visible={showExportDialog}
					onClose={handleExportDialogClose}
				/>

				<SaveRecipeTypeModal
					visible={showSaveModal}
					onClose={handleSaveModalClose}
					onSaveToCloud={saveToCloud}
					onSaveToFile={saveToFile}
				/>
			</div>
		</Sider>
	);
}

export default MainMenu;
